import { Breadcrumb, Layout, Menu, Row } from 'antd'
import { get, isString } from 'lodash'
import queryString from "query-string"
import React, { useEffect } from "react"
import { Link } from 'react-router-dom'
import CommonTypeProps from '../../../common/CommonTypeProps'
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout'
import { MetaHeader } from '../../../shares/MetaHeader'
import AccountLogsComponent from './account-logs/AccountLogsComponent'
import { ChangelogContainer } from './changelog/ChangelogContainer'
import { ExportActivityContainer } from './export-logs/ExportActivityContainer'
import AccessLogsComponent from './access-logs/AccessLogsComponent'
import { useSelector } from '../../../redux/store'
import { IState } from '../../../redux/reducer'
import { User } from 'o2o_layout/lib/common/model'

export interface IConsoleUsersActivityProps extends CommonTypeProps {

}

const ConsoleUsersActivityComponent = (props: IConsoleUsersActivityProps) => {

    const [selectedTab, setSelectedTab] = React.useState("")
    const user: User = useSelector((state: IState) => state.user)


    useEffect(() => {
        const query = queryString.parse(props.location.search)
        if (query.tab && isString(query.tab)) {
            setSelectedTab(query.tab)
        } else {
            setSelectedTab("account-logs")
        }
    }, [props.location.search])




    const renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Admin</Breadcrumb.Item>
                    <Breadcrumb.Item>Activities</Breadcrumb.Item>
                </Breadcrumb>
            </Row>

        )
    }

    const checkIsYODYOrg = () => {
        const yodyOrgId = "232862ea98f40d3e0dc65da6acdca7a6"
        if (get(user.orgCtx, 'orgId') === yodyOrgId) {
            return true
        } else {
            return false
        }
    }

    const isYODYOrg = checkIsYODYOrg()

    return (
        <DashboardPageLayout  {...props} selectedLeftNav="activity" selectedSubMenu="console">

            <div className="org-page">

                <MetaHeader title={props.ui.meta.title} />

                <div className="contentLayout">
                    {renderBreadCrumb()}
                    <br />
                    <div className="containerInner">

                        <Layout>
                            <Layout.Sider style={{ marginRight: 12, background: "#fff" }}>
                                <Menu style={{ height: "inherit" }} selectedKeys={[selectedTab]}>
                                    <Menu.Item key={"account-logs"}>
                                        <Link to={{
                                            pathname: "console-activity",
                                            search: "tab=account-logs"
                                        }} >
                                            Account Logs
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key={"export-logs"}>
                                        <Link to={{
                                            pathname: "console-activity",
                                            search: "tab=export-logs"
                                        }} >
                                            Export Logs
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key={"change-logs"}>
                                        <Link to={{
                                            pathname: "console-activity",
                                            search: "tab=change-logs"
                                        }} >
                                            Change Log
                                        </Link>
                                    </Menu.Item>
                                    {!isYODYOrg && <Menu.Item key={"access-logs"}>
                                        <Link to={{
                                            pathname: "console-activity",
                                            search: "tab=access-logs"
                                        }} >
                                            Access Logs
                                        </Link>
                                    </Menu.Item>}
                                </Menu>
                            </Layout.Sider>

                            <Layout.Content style={{ overflow: "hidden" }}>

                                {selectedTab === "account-logs" ?
                                    <AccountLogsComponent
                                        {...props}
                                    />
                                    : null}

                                {selectedTab === "export-logs" ?
                                    <ExportActivityContainer
                                        {...props}
                                    />
                                    : null}

                                {selectedTab === "change-logs" ?
                                    <ChangelogContainer
                                        {...props}
                                    />
                                    : null}

                                {
                                    selectedTab === 'access-logs' ?
                                        <AccessLogsComponent
                                            {...props}
                                        /> :
                                        null
                                }

                            </Layout.Content>

                        </Layout>


                    </div>
                </div>

            </div>
        </DashboardPageLayout>
    )
}

export default ConsoleUsersActivityComponent
