import { Breadcrumb, Button, Col, DatePicker, Form, Input, Row, Select, Table, Tooltip, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { ColumnProps } from 'antd/lib/table'
import { isArray, isEmpty, size } from 'lodash'
import moment from 'moment'
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAsyncRetry } from 'react-use'
import CommonTypeProps, { ICommonPayload } from '../../../common/CommonTypeProps'
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout'
import DrawerViewJSON from '../../../shares/DrawerViewJSON'
import { MetaHeader } from '../../../shares/MetaHeader'
import { TagPrimaryColor } from '../../../shares/TagPrimaryColor'
import { TypeCriteriaExportsHistory } from '../exports-history/DAL'
import { LoginActivityDAL } from './DAL'
import { LoginActivities, TypeLoginActivity } from './model'
import { O2OFormItem } from '../../../shares/O2OFormItem'


interface ILoginActivityProps extends CommonTypeProps {

}


const TYPE_DISPATCH_CRITERIA = "TYPE_DISPATCH_CRITERIA"

const initCriteria: ICommonPayload<TypeCriteriaExportsHistory> = {
    loading: false,
    type: TYPE_DISPATCH_CRITERIA,
    payload: {} as TypeCriteriaExportsHistory,
}

const reducerCriteria = (state: ICommonPayload<TypeCriteriaExportsHistory>, action: ICommonPayload<TypeCriteriaExportsHistory>) => {
    switch (action.type) {
        case TYPE_DISPATCH_CRITERIA:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}


const LoginActivityComponent = (props: ILoginActivityProps) => {
    const [criteria, dispatchCriteria] = React.useReducer(reducerCriteria, initCriteria)

    const [selectedRecord, setSelectedRecord] = useState(undefined as undefined | TypeLoginActivity)
    const [visibleViewJSON, setVisibleViewJSON] = useState(false)


    useEffect(() => {
        dispatchCriteria({
            loading: false,
            type: TYPE_DISPATCH_CRITERIA,
            payload: { ...{ page: 0, pageSize: 10 }, ...criteria.payload } as TypeCriteriaExportsHistory,
        })
        return () => {
            dispatchCriteria({
                loading: false,
                type: TYPE_DISPATCH_CRITERIA,
                payload: {} as TypeCriteriaExportsHistory,
            })
        }
    }, [])

    const load = async () => {
        const result = props.commonActionFromDALV2(LoginActivityDAL.search, criteria.payload)
        try {
            const response = await result
            return response
        } catch (error) {
            return new LoginActivities()
        }
    }

    const stateLoginActivity = useAsyncRetry(async () => {
        if (!isEmpty(criteria.payload)) {
            return await load()
        }
        return new LoginActivities()
    }, [criteria])

    const loginActivity = stateLoginActivity.value || new LoginActivities()

    const renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Activity</Breadcrumb.Item>
                    <Breadcrumb.Item>Login Activity</Breadcrumb.Item>
                </Breadcrumb>

            </Row>

        )
    }

    const columns: ColumnProps<TypeLoginActivity>[] = [
        {
            title: "#",
            key: "index",
            sorter: false,
            width: 40,
            align: "center",
            render: (text: any, record: TypeLoginActivity, index: number) => {
                return index + 1
            }
        }, {
            title: "Email",
            key: "email",
            sorter: false,
            width: 160,
            ellipsis: true,
            render: (text: any, record: TypeLoginActivity, index: number) => {
                return <LongTextTd text={record.userId} />
            }
        }, {
            title: "Action at",
            key: "action_at",
            width: 120,
            ellipsis: true,
            render(text: any, record: TypeLoginActivity, index: number) {
                return record.actionAt ? (
                    <Tooltip title={<>Action at {moment(record.actionAt).format('LLLL')} by {record.actionBy} </>}>
                        {moment(record.actionAt).fromNow()}
                    </Tooltip>
                ) : "--/--"
            },
        }, {
            title: "Status",
            key: "status",
            width: 100,
            ellipsis: true,
            render(text: any, record: TypeLoginActivity, index: number) {
                return <TagPrimaryColor children={record.status} uiSetting={props.uiSetting} />
            },
        }, {
            title: "Source",
            key: "source",
            ellipsis: true,
            width: 100,
            render: (text: any, record: TypeLoginActivity, index: number) => {
                return <TagPrimaryColor children={record.source} uiSetting={props.uiSetting} />

            }
        }, {
            title: "Device",
            key: "device",
            sorter: false,
            width: 150,
            render: (text: any, record: TypeLoginActivity, index: number) => {
                return <LongTextTd text={record.deviceCtx} />
            }
        }, {
            title: "OS Name",
            key: "os_name",
            sorter: false,
            width: 100,
            ellipsis: true,
            render: (text: any, record: TypeLoginActivity, index: number) => {
                return <LongTextTd text={record.osNameCtx} />
            }
        }, {
            title: "Browser",
            key: "browser",
            sorter: false,
            width: 100,
            ellipsis: true,
            render: (text: any, record: TypeLoginActivity, index: number) => {
                return <LongTextTd text={record.browserNameCtx} />
            }
        }, {
            title: "IP Adress",
            key: "ip",
            sorter: false,
            width: 150,
            ellipsis: true,
            render: (text: any, record: TypeLoginActivity, index: number) => {
                return <LongTextTd text={record.ipAddress} />
            }
        }, {
            title: "Message",
            key: "message",
            sorter: false,
            ellipsis: true,
            render: (text: any, record: TypeLoginActivity, index: number) => {
                return <LongTextTd text={record.extraValue} />
            }
        }, {
            title: "Action",
            key: "action",
            width: 70,
            fixed: "right",
            render(text: any, record: TypeLoginActivity, index: number) {
                return (
                    <>
                        <Button size="small" icon="select" type="link" onClick={() => {
                            setSelectedRecord(record)
                            setVisibleViewJSON(true)
                        }} />
                    </>
                )
            },
        },

    ]

    return (
        <DashboardPageLayout  {...props} selectedLeftNav="login-history" selectedSubMenu="activity" >
            <div className="org-page">

                <MetaHeader title={props.ui.meta.title} />

                <div className="contentLayout">
                    {renderBreadCrumb()}
                    <br />
                    <div className="containerInner">
                        <Row type='flex' gutter={[10, 10]}>
                            <Col xs={24}>
                                <Typography.Title level={4}>
                                    Login Activity
                                </Typography.Title>
                            </Col>
                            <Col xs={24}>
                                <FilterLoginActivityComponent
                                    loading={stateLoginActivity.loading}
                                    onSearch={(payload: any) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: { ...criteria.payload, ...(payload as TypeCriteriaExportsHistory) },
                                            type: TYPE_DISPATCH_CRITERIA,
                                        });
                                    }}
                                />
                            </Col>
                            <Col xs={24}>
                                <InfoList
                                    loading={stateLoginActivity.loading}
                                    list={loginActivity}
                                    refresh={stateLoginActivity.retry}
                                />
                            </Col>
                            <Col xs={24}>
                                <Table
                                    loading={stateLoginActivity.loading}
                                    columns={columns}
                                    dataSource={loginActivity.results.content}
                                    rowKey="id"
                                    size="small"
                                    scroll={{ x: 'calc(700px + 50%)' }}
                                    pagination={false}
                                />
                            </Col>
                            <Col xs={24}>
                                <O2OCustomPaging
                                    list={loginActivity}
                                    onChange={(e: number) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: { ...criteria.payload, ...({ page: e - 1 } as TypeCriteriaExportsHistory) },
                                            type: TYPE_DISPATCH_CRITERIA,
                                        });
                                    }}
                                    onChangePageSize={(e: number) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: { ...criteria.payload, ...({ page: 0, pageSize: e } as TypeCriteriaExportsHistory) },
                                            type: TYPE_DISPATCH_CRITERIA,
                                        });
                                    }}
                                />
                            </Col>

                            <DrawerViewJSON
                                data={selectedRecord}
                                onClose={() => {
                                    setSelectedRecord(undefined)
                                    setVisibleViewJSON(false)
                                }}
                                visible={visibleViewJSON}
                            />

                        </Row>
                    </div>
                </div>

            </div>
        </DashboardPageLayout>
    )
}

export default LoginActivityComponent

interface IFilterProps extends FormComponentProps {
    onSearch: (payload: any) => void
    loading: boolean
}

const FilterLoginActivityComponent = Form.create<IFilterProps>()(
    (props: IFilterProps) => {

        const onSubmit = () => {
            props.form.validateFields((err, values: any) => {
                if (!err) {
                    if (isArray(values.time) && size(values.time) === 2) {
                        values['fromTime'] = moment(values.time[0]).toDate().getTime()
                        values['toTime'] = moment(values.time[1]).toDate().getTime()
                    } else {
                        values['fromTime'] = 0
                        values['toTime'] = 0
                    }
                    delete values.time
                    props.onSearch(values)
                }
            })
        }

        return <Form hideRequiredMark colon={false}>
            <Row type="flex" gutter={[5, 5]}>
                <Col xs={24} lg={12} xl={8} xxl={6} >
                    <O2OFormItem label="Info">
                        {props.form.getFieldDecorator('freeText', {

                        })(<Input allowClear placeholder='Enter' />)}
                    </O2OFormItem>
                </Col>
                <Col xs={24} lg={12} xl={6} xxl={4} >
                    <O2OFormItem label="Status">
                        {props.form.getFieldDecorator('status', {

                        })(<Select allowClear mode={'multiple'} showArrow placeholder="Select(s)" >
                            <Select.Option value={'Success'}>Success</Select.Option>
                            <Select.Option value={'Failed'}>Failed</Select.Option>
                        </Select>)}
                    </O2OFormItem>
                </Col>
                <Col xs={24} lg={12} xl={7} xxl={8}  >
                    <O2OFormItem label="Time Range">
                        {props.form.getFieldDecorator("time", {

                        })(<DatePicker.RangePicker allowClear style={{ width: "100%" }} showTime={{ defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")] }} />)}
                    </O2OFormItem>
                </Col>
                <Col>
                    <O2OFormItem label=" ">
                        <Button icon='search' loading={props.loading} onClick={onSubmit} type='primary'>
                            Search
                        </Button>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form>
    }
)