import { Breadcrumb, Button, Col, Divider, Form, Input, Modal, Popover, Row, Table, Tag, Tooltip, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { ColumnProps } from 'antd/lib/table'
import _ from 'lodash'
import moment from "moment"
import numeral from 'numeral'
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAsyncRetry } from 'react-use'
import CommonTypeProps, { ICommonPayload } from '../../../common/CommonTypeProps'
import { BIGDATA_CONSTANT } from '../../../common/constant'
import { getCurrentURL } from '../../../common/helper'
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout'
import { ObjectModels } from '../../../redux/reducer/customFieldsReducer'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../redux/reducer/uiReducer'
import { useDispatch } from '../../../redux/store'
import DrawerViewJSON from '../../../shares/DrawerViewJSON'
import { MetaHeader } from '../../../shares/MetaHeader'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import { ExportsHistoryDAL, TypeCriteriaExportsHistory } from './DAL'
import DistinctFieldComponent from './DistinctField'
import { ExportsHistory, TypeExportsHistory } from './model'





export const ButtonDowloadLink = (props: { commonActionFromDALV2: any, id: string, onSuccess: (id: string) => void }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const onGetLinkDowload = async () => {
        setLoading(true)
        const result = await props.commonActionFromDALV2(ExportsHistoryDAL.getLinkDowload, props.id)
        try {
            const response = await result
            if (response.success) {
                props.onSuccess(_.get(response, "result.exportedUrl", ""))
                setLoading(false)
            } else {
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: response,
                } as IActionUI);
                setLoading(false)
            }
        } catch (error) {
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
            setLoading(false)
        }
    }

    return <Button style={{ paddingLeft: 0 }} type='link' size='small' onClick={onGetLinkDowload} loading={loading}>Link File</Button>

}

interface IFilterProps extends FormComponentProps {
    commonActionFromDALV2: any
    loading: boolean
    onSearch: (value: any) => void
}

const FilterExportActivity = Form.create<IFilterProps>()((props: IFilterProps) => {

    const onSearch = (e: any) => {
        e.preventDefault()
        props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                props.onSearch(values)
            }
        })
    }

    return (
        <Form colon={false} hideRequiredMark={true}>
            <Row gutter={[5, 5]} type='flex'>
                <Col sm={24} lg={12} xl={8} xxl={5}>
                    <O2OFormItem label="Info">
                        {props.form.getFieldDecorator("freeText", {})(
                            <Input.Search onPressEnter={onSearch} allowClear placeholder="Search..." />
                        )}
                    </O2OFormItem>
                </Col>
                <Col sm={24} lg={12} xl={8} xxl={5}>
                    <DistinctFieldComponent
                        type='category'
                        commonActionFromDALV2={props.commonActionFromDALV2}
                        label='Category'
                        keyField='categories'
                        form={props.form}
                        placeholder='Select(s)'
                    />
                </Col>

                <Col sm={24} lg={12} xl={8} xxl={5}>
                    <DistinctFieldComponent
                        type='action'
                        commonActionFromDALV2={props.commonActionFromDALV2}
                        label='Notif Channel'
                        keyField='actions'
                        form={props.form}
                        placeholder='Select(s)'
                    />
                </Col>

                <Col sm={24} lg={12} xl={8} xxl={5}>
                    <DistinctFieldComponent
                        type='source'
                        commonActionFromDALV2={props.commonActionFromDALV2}
                        label='Source'
                        keyField='sources'
                        form={props.form}
                        placeholder='Select(s)'
                    />
                </Col>

                <Col>
                    <O2OFormItem label={<span></span>}>
                        <Button loading={props.loading} type="primary" onClick={onSearch} icon="search">Search</Button>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form>
    )
})






interface IExportsHistoryProps extends CommonTypeProps {
    allMoM: ObjectModels
    getAllCustomFieldObjects: any
}

const TYPE_DISPATCH_CRITERIA = "TYPE_DISPATCH_CRITERIA"

const initCriteria: ICommonPayload<TypeCriteriaExportsHistory> = {
    loading: false,
    type: TYPE_DISPATCH_CRITERIA,
    payload: {} as TypeCriteriaExportsHistory,
}

const reducerCriteria = (state: ICommonPayload<TypeCriteriaExportsHistory>, action: ICommonPayload<TypeCriteriaExportsHistory>) => {
    switch (action.type) {
        case TYPE_DISPATCH_CRITERIA:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}

const ExportsHistoryComponent = (props: IExportsHistoryProps) => {
    const [criteria, dispatchCriteria] = React.useReducer(reducerCriteria, initCriteria)
    const [visibleViewJSON, setVisibleViewJSON] = useState(false as boolean)
    const [dataFilter, setDataFilter] = useState(undefined as any)
    const [selectedRecord, setSelectedRecord] = useState(undefined as undefined | TypeExportsHistory)
    const allMoM = props.allMoM

    const load = async () => {
        const result = await props.commonActionFromDALV2(ExportsHistoryDAL.search, criteria.payload)
        try {
            const response: ExportsHistory = await result
            if (response.success) {
                return response
            }
            return new ExportsHistory()
        } catch (error) {
            return new ExportsHistory()
        }
    }

    React.useEffect(() => {
        props.getAllCustomFieldObjects()
    }, [])

    React.useEffect(() => {
        props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.ExportsHistory })
        const timer = setTimeout(() => {
            props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.ExportsHistory,
                title: BIGDATA_CONSTANT.trackTitle.ExportsHistory,
                app: "CONSOLE",
                url: getCurrentURL(props.location)
            })
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    React.useEffect(() => {
        dispatchCriteria({
            loading: false,
            type: TYPE_DISPATCH_CRITERIA,
            payload: { page: 0, pageSize: 10 } as TypeCriteriaExportsHistory
        })
        return () => {
            dispatchCriteria({
                loading: false,
                type: TYPE_DISPATCH_CRITERIA,
                payload: {} as TypeCriteriaExportsHistory
            })
        }
    }, [])

    const stateExportsHistory = useAsyncRetry(async () => {
        if (!_.isEmpty(criteria.payload)) {
            const data: ExportsHistory = await load()
            return data
        }
        return new ExportsHistory()
    }, [criteria])

    const exportsHistory = stateExportsHistory.value ? stateExportsHistory.value : new ExportsHistory()

    const renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Activity</Breadcrumb.Item>
                    <Breadcrumb.Item>Exports History</Breadcrumb.Item>
                </Breadcrumb>
            </Row>

        )
    }

    const columns: ColumnProps<TypeExportsHistory>[] = [
        {
            title: "#",
            key: "index",
            sorter: false,
            width: 40,
            align: "center",
            render: (text: any, record: TypeExportsHistory, index: number) => {
                return index + 1
            }
        }, {
            title: "Download link",
            key: "dowloadLink",
            sorter: false,
            width: 120,
            ellipsis: true,
            render: (text: any, record: TypeExportsHistory, index: number) => {
                const yesterday = moment().subtract(1, 'd')
                const isOverADayAgo = moment(record.endTime).isBefore(yesterday)
                switch (isOverADayAgo) {
                    case true:
                        return "Expired"
                    case false:
                        return <ButtonDowloadLink
                            onSuccess={(link: string) => {
                                Modal.confirm({
                                    title: 'Dowload',
                                    content: 'Click the Download button to download your file.',
                                    okText: 'Dowload',
                                    cancelText: 'Close',
                                    onOk: () => {
                                        window.open(link)
                                    }
                                });
                            }}
                            id={record.id || ""}
                            commonActionFromDALV2={props.commonActionFromDALV2}
                        />
                    default:
                        return "Expired"
                }
            }
        }, {
            title: "Start Time",
            key: "start_time",
            width: 120,
            ellipsis: true,
            render(text: any, record: TypeExportsHistory, index: number) {
                return record.startTime ? (
                    <Tooltip title={moment(record.startTime).format("DD/MM/YYYY HH:mm:ss")}>
                        {moment(record.startTime).fromNow()}
                    </Tooltip>
                ) : "--/--"
            },
        }, {
            title: "End Time",
            key: "end_time",
            width: 120,
            ellipsis: true,
            render(text: any, record: TypeExportsHistory, index: number) {
                return record.startTime ? (
                    <Tooltip title={moment(record.endTime).format("DD/MM/YYYY HH:mm:ss")}>
                        {moment(record.endTime).fromNow()}
                    </Tooltip>
                ) : "--/--"
            },
        }, {
            title: "Total",
            key: "total",
            align: "right",
            ellipsis: true,
            width: 80,
            render: (text: any, record: TypeExportsHistory, index: number) => {
                return (
                    <Popover content={numeral(record.totalRecords).format("0,00")}>
                        {numeral(record.totalRecords).format("0.[0]a")}
                    </Popover>
                )

            }
        }, {
            title: "Status",
            key: "status",
            sorter: false,
            width: 100,
            render: (text: any, record: TypeExportsHistory, index: number) => {
                if (record.status === "Success") {
                    return <Tag color='green'>{record.status}</Tag>
                } else if (record.status === "Failed") {
                    return <Tag color='red'>{record.status}</Tag>
                } else {
                    return <Tag color='gold'>{record.status}</Tag>
                }
            }
        }, {
            title: "Source",
            key: "source",
            sorter: false,
            width: 180,
            ellipsis: true,
            render: (text: any, record: TypeExportsHistory, index: number) => {
                const model = allMoM.results[record.source || ""] || {}
                const { code, name } = model
                return <LongTextTd textDisplay={name || record.source} text={code || record.source} />
            }
        }, {
            title: "Reason",
            key: "reason",
            sorter: false,
            ellipsis: true,
            render: (text: any, record: TypeExportsHistory, index: number) => {
                return <LongTextTd text={record.reason} />
            }
        }, {
            title: "Action",
            key: "action",
            width: 90,
            fixed: "right",
            render(text: any, record: TypeExportsHistory, index: number) {
                let criteria = {}
                try {
                    if (record.criteria && JSON.parse(record.criteria)) {
                        criteria = JSON.parse(record.criteria)
                    } else {
                        criteria = {}
                    }
                } catch (error) {
                    criteria = {}
                }
                return (
                    <>
                        <Tooltip title="Data Filter">
                            <Button size="small" icon="select" type="link" onClick={() => {
                                setVisibleViewJSON(true)
                                setDataFilter(criteria)
                            }} />
                        </Tooltip>

                        <Divider type="vertical" />

                        <Tooltip title="View Json">
                            <Button size="small" icon="eye" type="link" onClick={() => {
                                setSelectedRecord(record)
                                setVisibleViewJSON(true)
                            }} />
                        </Tooltip>
                    </>
                )
            },
        },

    ]

    return (
        <DashboardPageLayout  {...props} selectedLeftNav="exports-history" selectedSubMenu="activity" >
            <div className="org-page">

                <MetaHeader title={props.ui.meta.title} />

                <div className="contentLayout">
                    {renderBreadCrumb()}
                    <br />
                    <div className="containerInner">
                        <Row type='flex' gutter={[10, 10]}>

                            <Col xs={24}>
                                <Typography.Title level={4}>Exports History</Typography.Title>
                            </Col>

                            <Col xs={24}>
                                <FilterExportActivity
                                    commonActionFromDALV2={props.commonActionFromDALV2}
                                    loading={stateExportsHistory.loading}
                                    onSearch={(values: any) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: {
                                                ...criteria.payload,
                                                ...{ page: 0, ...values },
                                            },
                                            type: TYPE_DISPATCH_CRITERIA,
                                        })
                                    }}
                                />
                            </Col>

                            <Col xs={24}>
                                <InfoList
                                    loading={stateExportsHistory.loading}
                                    list={exportsHistory}
                                    refresh={stateExportsHistory.retry}
                                />
                            </Col>

                            <Col xs={24}>
                                <Table
                                    loading={stateExportsHistory.loading}
                                    columns={columns}
                                    dataSource={exportsHistory.results.content}
                                    rowKey="id"
                                    size="small"
                                    scroll={{ x: 'calc(700px + 50%)' }}
                                    pagination={false}
                                />
                            </Col>

                            <Col xs={24}>
                                <O2OCustomPaging
                                    list={exportsHistory}
                                    onChange={(e: number) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: { ...criteria.payload, ...({ page: e - 1 } as TypeCriteriaExportsHistory) },
                                            type: TYPE_DISPATCH_CRITERIA,
                                        });
                                    }}
                                    onChangePageSize={(e: number) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: { ...criteria.payload, ...({ page: 0, pageSize: e } as TypeCriteriaExportsHistory) },
                                            type: TYPE_DISPATCH_CRITERIA,
                                        });
                                    }}
                                />
                            </Col>

                            <DrawerViewJSON
                                data={dataFilter || selectedRecord}
                                onClose={() => {
                                    setSelectedRecord(undefined)
                                    setDataFilter(undefined)
                                    setVisibleViewJSON(false)
                                }}
                                visible={visibleViewJSON}
                            />

                        </Row>
                    </div>
                </div>
            </div>
        </DashboardPageLayout>
    )
}

export default ExportsHistoryComponent
